import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link, graphql, useStaticQuery } from "gatsby"

const NotFoundPage = () => {
  const data = {
    title: ["Lo siento, página", "no encontrada"],
    subtitle: "No se pudo encontrar la página que solicitaste.",
    cta: {
      text: "Regresar al inicio",
      link: "/"
    }
  }
  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        name
      }
    }
  `)
  return(
    <Layout>
      <Seo title={realEstate.name} />
      <div className="nf-container d-flex flex-column justify-content-center h-100">
        <div className="nf-top d-flex justify-content-center align-items-lg-end align-items-center text-center">
          <h1 className="nf-number close-text">404</h1>
        </div>
        <div className="nf-text d-flex justify-content-center nf-bg-color nf-bt-border">
          <div className="nf-text-wrapper d-flex flex-column justify-content-center align-items center text-center">
            <h2 className="nf-title mt-3">{data.title[0]}<br />{data.title[1]}</h2>
            <p className="nf-subtitle">{data.subtitle}</p>
            <Link to={data.cta.link} 
              className="btn white border nf-btn mx-auto mt-5 mb-3">{data.cta.text}</Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
